import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import pick from "lodash/pick";
import useSWR, { keyInterface } from "swr";
import { createContainer } from "unstated-next";
import stableStringify from "fast-json-stable-stringify";
import { fetcherFn, ConfigInterface } from "swr/dist/types";

import { useAuth } from "app/auth-container";
import { getAPIMockEndPoints } from "./config";
import { ApiClientReturn, createAPIClient } from "./create-api-client";
import { createMockAPIClient } from "./create-mock-api-client";

type apiType = {
  [key: string]: Function | apiType;
};

export function useSearchSWR(
  key: string | null,
  fetcher: (options: any) => Promise<any>,
  searchOptions: any
) {
  const stringifiedSearchOptions = stableStringify(searchOptions); // serialize the search options to set hook dependencies

  return useSWR([key, stringifiedSearchOptions], () => fetcher(searchOptions));
}

function combineAPI(getToken): ApiClientReturn {
  const distantAPI = createAPIClient({ getToken });
  const mockAPI = createMockAPIClient();

  const mockEndPoints = getAPIMockEndPoints();

  if (isEqual(mockEndPoints, ["*"])) {
    console.info("=== All API end-points are mocked! ===");
    return merge(distantAPI, mockAPI);
  }

  if (mockEndPoints.length > 0) {
    console.info("=== Mocked API end-points ===", mockEndPoints);
  }

  return merge(distantAPI, pick(mockAPI, mockEndPoints));
}

/**
 * Given an api object, returns Map<fn, path>, where:
 *  fn is any function value in the api,
 *  path is `api.${subpath}` where subpath is the path to the first occurrence of fn in the api
 *
 * For example:
 * { cases: { attachments: { get: fn }}} => { fn: 'api.cases.attachments.get' }
 *
 * This function is used to map any function in api to its path string.
 */
function getApiMap(api: apiType) {
  const ret = new Map<Function, string>();
  function run(obj: apiType, basePath: string) {
    Object.entries(obj).forEach(([k, v]) => {
      const path = [basePath, k].join(".");
      if (typeof v === "function") ret.set(v, path);
      else run(v, path);
    });
  }
  run(api, "api");
  return ret;
}

function useApiWithApiMap() {
  const { getTokenSilently } = useAuth();
  const api = combineAPI(getTokenSilently);
  const apiMap = getApiMap(api);
  return { api, apiMap };
}

export const APIContainer = createContainer(useApiWithApiMap);
export function useAPI() {
  const { api } = APIContainer.useContainer();
  return api;
}

/**
 * Thin wrapper of useSWR, which:
 * - searchs fetcher fn in the api provided by useAPI(), then
 * - prepend the path of the fn in the api, to the key.
 *
 * @param fn Any fetcher function contained in the api returned from useAPI()
 * @param key Same as key in useSWR. The path of fn in the api, will be prepended
 * @param config Same as config in useSWR
 *
 * Note that
 * - the order of [fn, key] in the signature is different from [key, fn] in useSWR.
 * - null returned and error thrown in key have same effect as of useSWR
 */
export function useSWRApi<Data = any, Error = any>(
  fn: fetcherFn<Data>,
  key: keyInterface,
  config?: ConfigInterface<Data, Error>
) {
  const { apiMap } = APIContainer.useContainer();
  function keyFn() {
    const path = apiMap.get(fn);
    if (!path) throw new Error("Undefined fetcher in api");
    // Shortcut-circuit to error throwing and null
    const _key = typeof key === "function" ? key() : key;
    if (_key === null) return null;
    return Array.isArray(_key) ? [path, ..._key] : [path, _key];
  }
  // Discard the first key as we do not need it
  const fetcher = (_: string, ...args: any) => fn(...args);
  return useSWR<Data, Error>(keyFn, fetcher, config);
}
