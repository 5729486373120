import React from "react";
import { useWindowSize } from "react-use";
import { Flex, FlexProps } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  growable: boolean;
} & FlexProps;

export const FullHeight = ({ children, growable, ...props }: Props) => {
  const { height } = useWindowSize();

  return (
    <Flex
      width="100%"
      flexDirection="column"
      {...props}
      style={{
        [growable ? "minHeight" : "height"]: height
      }}
    >
      {children}
    </Flex>
  );
};
