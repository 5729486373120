import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export const ErrorLabel = ({ children, ...otherProps }: BoxProps) => {
  return (
    <Box color="read.600" {...otherProps}>
      {children}
    </Box>
  );
};
