import { Machine, assign, MachineConfig, MachineOptions } from "xstate";
import { useMachine } from "@xstate/react";
import { createContainer } from "unstated-next";
import pMap from "p-map";
import groupBy from "lodash/groupBy";
import debugModule from "debug";

import { useAPI } from "api";
import {
  ImageEditableData,
  UploadCasesImagesContainer
} from "./upload-cases-images-container";
import { CaseFormData } from "components/cases/case-form";

const debug = debugModule("medmain:upload");

export function useUploadBulkImagesStateMachine() {
  const api = useAPI();
  const upload = UploadCasesImagesContainer.useContainer();
  const machine = createStateMachine({ upload, api });
  const showDevTools = process.env.NODE_ENV !== "production"; // Redux DevTools browser extension to debug in local
  return useMachine(machine, { devTools: showDevTools });
}

export function useBulkUploadImages() {
  const [current, send] = useUploadBulkImagesStateMachine();
  const imageTotal = current.context.files.length;
  return { send, current, imageTotal };
}

export const BulkUploadContainer = createContainer(useBulkUploadImages);

export type BulkUploadFile = Medmain.UploadFile & {
  parsed?: boolean;
  caseExists?: boolean;
  fileAlreadyExists?: boolean;
};

interface MachineContext {
  files: BulkUploadFile[];
}

type StateSchema = {
  states: {
    adding: {};
    parsing: {};
    parsingError: {};
    previewing: {};
    uploading: {};
    done: {};
  };
};

interface AddFilesEvent {
  type: "ADD_FILES";
  files: File[];
}
interface RemoveFileEvent {
  type: "REMOVE_FILE";
  filename: string;
}
interface PreviewEvent {
  type: "PREVIEW";
  organizationId: string;
}
interface BackEvent {
  type: "BACK";
}
interface UploadStartEvent {
  type: "UPLOAD_START";
  caseData: CaseFormData;
  imageData: ImageEditableData;
}
interface CaseCreatedEvent {
  type: "CASE_CREATED";
  file: BulkUploadFile;
  caseId: string;
}
interface CaseCreationErrorEvent {
  type: "CASE_CREATION_ERROR";
  caseNumber: string;
}
interface StartFileUploadEvent {
  type: "START_FILE_UPLOAD";
  file: BulkUploadFile;
}
interface UploadProgressEvent {
  type: "UPLOAD_PROGRESS";
  file: BulkUploadFile;
  value: number;
}
interface UploadErrorEvent {
  type: "UPLOAD_ERROR";
  file: BulkUploadFile;
}
interface EndFileUploadEvent {
  type: "END_FILE_UPLOAD";
  file: BulkUploadFile;
}
interface EndFileGroupUploadEvent {
  type: "END_FILE_GROUP_UPLOAD";
  file: BulkUploadFile;
}
interface ResetEvent {
  type: "RESET";
}

type UploadEvent =
  | AddFilesEvent
  | RemoveFileEvent
  | PreviewEvent
  | BackEvent
  | UploadStartEvent
  | CaseCreatedEvent
  | CaseCreationErrorEvent
  | UploadProgressEvent
  | UploadErrorEvent
  | StartFileUploadEvent
  | EndFileUploadEvent
  | EndFileGroupUploadEvent
  | ResetEvent;

function createStateMachine({
  upload,
  api
}: {
  upload: ReturnType<typeof UploadCasesImagesContainer.useContainer>;
  api: ReturnType<typeof useAPI>;
}) {
  const config: MachineConfig<MachineContext, StateSchema, UploadEvent> = {
    id: "bulk-upload",
    initial: "adding",
    states: {
      adding: {
        on: {
          ADD_FILES: {
            actions: ["addFiles"]
          },
          REMOVE_FILE: {
            actions: ["removeFile"]
          },
          PREVIEW: {
            target: "parsing"
          }
        }
      },
      parsing: {
        invoke: {
          src: "parseFiles",
          onDone: {
            target: "previewing",
            actions: ["parseFileSuccess"]
          },
          onError: {
            target: "parsingError",
            actions: ["logError"]
          }
        }
      },
      parsingError: {
        on: {
          BACK: {
            target: "adding"
          }
        }
      },
      previewing: {
        on: {
          BACK: {
            target: "adding"
          },
          UPLOAD_START: {
            target: "uploading"
          },
          REMOVE_FILE: {
            actions: ["removeFile"]
          }
        }
      },
      uploading: {
        invoke: {
          src: "startUploadFileGroup"
        },
        on: {
          CASE_CREATED: { actions: ["caseCreated"] },
          START_FILE_UPLOAD: { actions: ["startFileUpload"] },
          UPLOAD_PROGRESS: { actions: ["uploadProgress"] },
          END_FILE_UPLOAD: {
            actions: ["endFileUpload"]
          },
          END_FILE_GROUP_UPLOAD: { target: "done", actions: [] },
          UPLOAD_ERROR: { actions: ["setError"] },
          CASE_CREATION_ERROR: { actions: ["setErrorByCaseNumber"] }
        }
      },
      done: {
        on: {
          RESET: {
            target: "adding",
            actions: ["reset"]
          }
        }
      }
    }
  };

  const services = {
    parseFiles: async (context, event) => {
      const { organizationId } = event as PreviewEvent;
      const filenames = context.files.map(file => file.filename);
      return await api.batchImport.parseFiles({ organizationId, filenames });
    },
    //Invoking callbacks, see: https://xstate.js.org/docs/guides/communication.html#invoking-callbacks
    startUploadFileGroup: (context, event) => async (
      callback: (UploadEvent) => void,
      onReceive
    ) => {
      try {
        const { caseData, imageData } = event;
        await uploadAllFiles({
          caseData,
          imageData,
          files: context.files,
          upload,
          api,
          onStart: file => callback({ type: "START_FILE_UPLOAD", file }),
          onProgress: (file, value) => {
            callback({
              type: "UPLOAD_PROGRESS",
              file: file,
              value
            });
          },
          onCaseCreated: (file, caseId) =>
            callback({ type: "CASE_CREATED", file, caseId }),
          onCaseCreationError: ({ caseNumber }) =>
            callback({ type: "CASE_CREATION_ERROR", caseNumber }),
          onEnd: file => callback({ type: "END_FILE_UPLOAD", file }),
          onError: file => callback({ type: "UPLOAD_ERROR", file })
        });
        callback({ type: "END_FILE_GROUP_UPLOAD" });
      } catch (error) {
        console.error("Unable to upload the files", error);
        // TODO: dispatch the appropriate action when it fails
      }
    }
  };

  const findByFilename = context => filename =>
    context.files.find(file => file.filename === filename);

  // TODO we should be able to use assign with 2 types `Assign<Context,Event>` when defining actions
  // but there is something wrong with X-state 4.20... to be checked again in the future!
  // https://github.com/davidkpiano/xstate/issues/2276#issuecomment-864595142
  const actions = {
    addFiles: assign<MachineContext, any>({
      files: (context, event) => {
        const addedFiles: BulkUploadFile[] = (event as AddFilesEvent).files
          .filter(file => !findByFilename(context)(file.name)) // don't add duplicates
          .map((file: File) => ({
            addedAt: new Date(),
            filename: file.name,
            displayName: file.name,
            status: "added",
            uploadProgress: 0,
            size: file.size,
            file, // keep a reference to the `File` browser native object
            parsed: false,
            caseNumber: "",
            caseId: "",
            caseExists: false
          }));
        return [...context.files, ...addedFiles];
      }
    }),
    startFileUpload: assign<MachineContext, any>({
      files: (context, event) => {
        const filename = (event as any).file.filename;
        return context.files.map(file =>
          file.filename === filename ? { ...file, status: "uploading" } : file
        );
      }
    }),
    removeFile: assign<MachineContext, any>({
      files: (context, event) => {
        const filename = (event as RemoveFileEvent).filename;
        return context.files.filter(file => file.filename !== filename);
      }
    }),
    parseFileSuccess: assign<MachineContext, any>({
      files: (context, event: any) => {
        const findByFilename = filename =>
          event.data.find(item => item.filename === filename);
        return context.files.map(file => {
          const parsingResult = findByFilename(file.filename);
          return {
            ...file,
            parsed: true,
            caseId: parsingResult.caseId,
            caseNumber: parsingResult.caseNumber,
            caseExists: parsingResult.exists,
            fileAlreadyExists: parsingResult.fileAlreadyExists
          };
        });
      }
    }),
    logError(context, event) {
      console.error(`UNEXPECTED ERROR`, event.type, event.data);
    },
    caseCreated: assign<MachineContext, any>({
      files: (context, event) => {
        const {
          caseId,
          file: { filename }
        } = event as CaseCreatedEvent;
        return context.files.map(file =>
          file.filename === filename ? { ...file, caseId } : file
        );
      }
    }),
    uploadProgress: assign<MachineContext, any>({
      files: (context, event) => {
        const {
          file: { filename },
          value
        } = event as UploadProgressEvent;
        return context.files.map(file =>
          file.filename === filename ? { ...file, uploadProgress: value } : file
        );
      }
    }),
    setError: assign<MachineContext, any>({
      files: (context, event) => {
        const {
          file: { filename }
        } = event as UploadErrorEvent;
        return context.files.map(file =>
          file.filename === filename
            ? { ...file, status: "upload_failed" }
            : file
        );
      }
    }),
    setErrorByCaseNumber: assign<MachineContext, any>({
      files: (context, event) => {
        const { caseNumber } = event as CaseCreationErrorEvent;
        return context.files.map(file =>
          file.caseNumber === caseNumber
            ? { ...file, status: "upload_failed" }
            : file
        );
      }
    }),
    endFileUpload: assign<MachineContext, any>({
      files: (context, event) => {
        const {
          file: { filename }
        } = event as EndFileGroupUploadEvent;
        return context.files.map((file: BulkUploadFile) =>
          file.filename === filename ? { ...file, status: "uploaded" } : file
        );
      }
    }),
    reset: assign<MachineContext, any>({
      files: (context, event) => {
        return [];
      }
    })
  };

  const options: MachineOptions<MachineContext, UploadEvent> = {
    actions,
    services,
    guards: {},
    activities: {},
    delays: {}
  };

  const initialContext: MachineContext = {
    files: [] as BulkUploadFile[]
  };

  return Machine({ ...config, context: initialContext }, options);
}

async function uploadAllFiles({
  caseData,
  imageData,
  files,
  upload,
  api,
  onCaseCreated,
  onCaseCreationError,
  onStart,
  onProgress,
  onEnd,
  onError
}: {
  caseData: CaseFormData;
  imageData: ImageEditableData;
  files: BulkUploadFile[];
  upload: ReturnType<typeof UploadCasesImagesContainer.useContainer>;
  api: ReturnType<typeof useAPI>;
  onCaseCreated: (file: BulkUploadFile, caseId: string) => void;
  onCaseCreationError: ({ caseNumber }: { caseNumber: string }) => void;
  onStart?: (file: BulkUploadFile) => void;
  onProgress?: (file: BulkUploadFile, value: number) => void;
  onEnd: (file: BulkUploadFile) => void;
  onError: (file: BulkUploadFile) => void;
}) {
  const uploadCaseImages = async caseNumber => {
    const files = filesByCaseNumber[caseNumber];
    const caseId = await getCaseId(files[0]);

    if (caseId) {
      await upload.addFiles({
        caseId,
        caseNumber,
        filesToUpload: files.map(file => file.file),
        metaData: imageData,
        onStart,
        onProgress,
        onEnd,
        onError
      });
    }
  };

  const getCaseId = async (file): Promise<string | undefined> => {
    if (file.caseExists) {
      return file.caseId;
    }
    const { caseNumber } = file;
    debug(`Creating a new case`, caseNumber, caseData);
    try {
      const { id } = await api.cases.create({ ...caseData, caseNumber });
      debug("Case created", id);
      onCaseCreated(file, id);
      return id;
    } catch (error) {
      onCaseCreationError({ caseNumber });
      console.error("Unable to create the case", error);
    }
  };

  const filesByCaseNumber = groupBy(files, "caseNumber");
  const caseNumbers = Object.keys(filesByCaseNumber);

  await pMap(caseNumbers, uploadCaseImages, { concurrency: 1 });
}
