import { createContainer } from "unstated-next";

import { locales } from "locales";
import { resolveLocale } from "locales/resolver";
import { useAccount } from "./auth-container";
import { LocaleDictionary } from "locales/english";

const DEFAULT_LOCALE_ID = "ja";
const FALLBACK_LOCALE_ID = "en";

function useLocaleState() {
  const { language } = useAccount();

  const getLocale = () => {
    const localeId = language;
    return resolveLocale({
      localeId,
      locales,
      defaultLocaleId: DEFAULT_LOCALE_ID,
      fallbackLocaleId: FALLBACK_LOCALE_ID
    });
  };

  // TODO: implement setLocale()

  const locale = getLocale();
  return locale as LocaleDictionary;
}

export const LocaleContainer = createContainer(useLocaleState);

export const useLocale = () => LocaleContainer.useContainer(); // the hook to be used every where instead of the one from React UI Kit!
