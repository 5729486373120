import React, { useRef, useState } from "react";
import { Box, BoxProps, Flex, Tag } from "@chakra-ui/react";
import debounce from "lodash/debounce";

import { useAPI } from "api";
import { Select, Spinner } from "components/core";
import { NumberOf } from "components/batch-import/batch-import-utils";
import { SearchOptions } from "types";
import { PAGINATION_MINIMUM_LIMIT } from "components/common/pagination";

const { Option } = Select;

export type CaseOption = {
  caseNumber: Medmain.Case["caseNumber"];
  id: Medmain.Case["caseNumber"];
  isNewCase: boolean;
  images?: Medmain.Image["filename"][];
};

export const CaseNumberPicker = ({
  defaultCaseData,
  orgId,
  onChange
}: {
  defaultCaseData: CaseOption;
  orgId: string;
  onChange: (caseOption: CaseOption) => void;
}) => {
  const api = useAPI();

  const [isFetching, setIsFetching] = useState(false);
  const [cases, setCases] = useState<CaseOption[]>([defaultCaseData]);
  const [value, setValue] = useState(defaultCaseData.caseNumber);

  const lastFetchId = useRef(0); // "Ajax callback order flow" seen on https://ant.design/components/select/#components-select-demo-select-users

  const options = cases.map(caseOption => (
    <Option key={caseOption.id} value={caseOption.id}>
      <Flex align="center">
        <Box>{caseOption.caseNumber}</Box>
        <CaseSummary caseOption={caseOption} ml={2} />
      </Flex>
    </Option>
  ));

  const fetchCases = async caseNumber => {
    const searchOptions: SearchOptions = {
      query: [
        {
          field: "organizationId",
          operator: "is",
          value: orgId
        },
        { field: "caseNumber", operator: "is-like", value: `%${caseNumber}%` }
      ],
      order: [],
      limit: PAGINATION_MINIMUM_LIMIT,
      page: 1
    };
    const { data } = await api.cases.find(searchOptions);
    return data as Medmain.Case[];
  };

  const debounceDelay = 500;

  const onSearch = debounce(async value => {
    lastFetchId.current = lastFetchId.current + 1;
    if (value) {
      setValue(value);
      const fetchId = lastFetchId.current;
      setIsFetching(true);
      const foundCases = await fetchCases(value);
      if (fetchId !== lastFetchId.current) {
        return; // discard responses for previous requests
      }
      const existingCaseOptions: CaseOption[] = foundCases.map(
        ({ id, caseNumber, images }) => ({
          id,
          caseNumber,
          isNewCase: false,
          images: images.map(image => image.filename)
        })
      );

      const wasFound = existingCaseOptions.some(
        caseItem => caseItem.caseNumber === value
      );
      const allCasesOptions = wasFound
        ? existingCaseOptions
        : [
            ...existingCaseOptions,
            { caseNumber: value, isNewCase: true, id: "<New Case>" }
          ];

      setIsFetching(false);
      setCases(allCasesOptions);
    } else {
      // Reset the case to the default one when the field is emptied
      setValue(defaultCaseData.caseNumber);
      setCases([defaultCaseData]);
    }
  }, debounceDelay);

  return (
    <Select
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={isFetching ? <Spinner /> : null}
      onChange={id => {
        const foundOption = cases.find(caseOption => caseOption.id === id);
        if (!foundOption) return;
        const caseNumber = foundOption?.caseNumber;
        setValue(caseNumber);
        onChange(foundOption);
      }}
      onSearch={onSearch}
      showArrow
      showSearch
      style={{ width: "100%" }}
      value={value}
    >
      {options}
    </Select>
  );
};

const CaseSummary = ({
  caseOption,
  ...props
}: { caseOption: CaseOption } & BoxProps) => {
  const { images, isNewCase } = caseOption;

  if (isNewCase)
    return (
      <Tag variant="outline" colorScheme="primary" {...props}>
        New Case
      </Tag>
    );

  return (
    <Box color="gray.500" {...props}>
      (<NumberOf name="image" items={images} />)
    </Box>
  );
};
