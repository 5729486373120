import React from "react";
import { Progress as ChakraProgress, ProgressProps } from "@chakra-ui/react";

export const Progress = ({ value, ...props }: ProgressProps) => {
  return (
    <ChakraProgress
      value={value}
      colorScheme="primary"
      isAnimated
      hasStripe
      {...props}
    />
  );
};
