import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  fullWidth?: boolean;
  align?: "left" | "center" | "end";
};

export const Container = ({
  children,
  fullWidth = false,
  align = "left",
  ...props
}: Props & BoxProps) => {
  const isFullWidth = fullWidth ? {} : { maxWidth: "1320px" };

  const isAlign = {
    left: {},
    center: { margin: "0 auto" },
    right: { marginLeft: "auto" }
  }[align];

  const layoutProps = { ...isFullWidth, ...isAlign, ...props };

  return <Box {...layoutProps}>{children}</Box>;
};

// Needed when the main content is displayed inside a single column
// to let the user scroll since the `<Layout>` toot component has `overflow-y: hidden`
export const ScrollableContainer = (props: BoxProps) => (
  <Box h={{ lg: "100%" }} overflowY={{ lg: "auto" }} {...props} />
);
