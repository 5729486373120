import { useLocation, useHistory } from "react-router-dom";
import { parse, stringify } from "qs";

export function useQueryStringParams() {
  const location = useLocation();
  const history = useHistory();

  function readParams() {
    const { search } = location;
    return parse(search, { ignoreQueryPrefix: true });
  }

  function writeParams(changes) {
    const nextLocation = generateNextLocation(changes);
    history.push(nextLocation);
  }

  function generateNextLocation(changes) {
    const params = readParams();
    const updatedParams = { ...params, ...changes };
    return { ...location, search: stringify(updatedParams) };
  }

  return {
    readParams() {
      return readParams();
    },
    writeParams(changes) {
      return writeParams(changes);
    }
  };
}
