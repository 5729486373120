export interface ErrorsByKey {
  [fieldName: string]: string;
}
export interface ValidationError extends Error {
  errors?: ErrorsByKey;
}

export function createValidationError(
  message: string,
  errors: ErrorsByKey
): ValidationError {
  const error: ValidationError = new Error(message);
  error.errors = errors;
  return error;
}
