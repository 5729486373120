import React from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Box,
  BoxProps,
  Flex,
  ListItem,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  List,
  Text
} from "@chakra-ui/react";
import { MdCloudUpload } from "react-icons/md";
import orderBy from "lodash/orderBy";
import meanBy from "lodash/meanBy";

import { useLocale } from "app/locale";
import { UploadCasesImagesContainer } from "app/upload-cases-images-container";
import { NotificationsContainer, AppEvent } from "app/notification-container";
import {
  AddIcon,
  CheckIcon,
  Progress,
  TimeAgo,
  WarningIcon
} from "components/core";
import { FileIcon } from "components/cases/case-icons";

export const NotificationPopover = () => {
  const history = useHistory();
  const locale = useLocale();
  const { notifications } = NotificationsContainer.useContainer();
  const {
    currentFiles,
    completeCount,
    resetCompleteCount
  } = UploadCasesImagesContainer.useContainer();

  if (notifications.length === 0) return null; // don't show the button at all if there is no message

  const viewCase = caseId => history.push(`/cases/${caseId}/images`);

  const uploadingFiles = orderBy(
    currentFiles.filter(file => file.status === "uploading"),
    ["addedAt"],
    ["desc"]
  );
  const errorFiles = currentFiles.filter(
    file => file.status === "upload_failed"
  );

  const uploadProgress = meanBy(uploadingFiles, "uploadProgress");

  const getPillColor = () => {
    if (uploadingFiles.length > 0) {
      return "primary.500";
    }
    if (errorFiles.length > 0) {
      return "red.600";
    }
    return "green.600";
  };

  const getCount = () => {
    if (uploadingFiles.length > 0) {
      return uploadingFiles.length;
    }
    if (errorFiles.length > 0) {
      return errorFiles.length;
    }
    return completeCount;
  };
  const pillCount = getCount();

  return (
    <Popover onOpen={() => resetCompleteCount()} placement="bottom-end">
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              px={0}
              width="48px"
              h="40px"
              _focus={{ outline: "none" }}
              isDisabled={notifications.length === 0}
              backgroundColor={isOpen ? "gray.100" : "white"}
              variant="ghost"
            >
              <Flex
                flexDirection="column"
                position="relative"
                width="100%"
                alignItems="center"
              >
                <Icon as={MdCloudUpload} color="gray.400" boxSize="28px" />

                {uploadingFiles.length > 0 && (
                  <Box borderWidth="1px" borderColor="primary.100">
                    <Progress
                      value={uploadProgress * 100}
                      colorScheme={
                        uploadingFiles.length === 0 ? "green" : "primary"
                      }
                      height="5px"
                      w="28px"
                    />
                  </Box>
                )}
                {pillCount > 0 && (
                  <CountPill
                    count={pillCount}
                    backgroundColor={getPillColor()}
                  />
                )}
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            width={{ base: "calc(100vw - 30px)", md: "400px" }}
            mx={{ base: "15px", md: 0 }}
            zIndex={10000}
            _focus={{ outline: "none" }}
          >
            <PopoverCloseButton />
            <PopoverBody padding="0" backgroundColor="white">
              <Tabs colorScheme="primary" size="md">
                <TabList>
                  <Tab _focus={{ outline: "none" }}>{locale.messagesTab}</Tab>
                  <Tab
                    _focus={{ outline: "none" }}
                    color={uploadingFiles.length === 0 ? "gray.400" : undefined}
                  >
                    {locale.uploadingTab}
                    {uploadingFiles.length > 0 && (
                      <Badge ml={2}>{uploadingFiles.length}</Badge>
                    )}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={0}>
                    <NotificationList
                      notifications={notifications}
                      onClick={caseId => {
                        viewCase(caseId);
                        if (onClose) onClose();
                      }}
                    />
                  </TabPanel>
                  <TabPanel>
                    <UploadingFiles
                      files={uploadingFiles}
                      onClick={caseId => {
                        viewCase(caseId);
                        if (onClose) onClose();
                      }}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const NotificationList = ({
  notifications,
  onClick
}: {
  notifications: AppEvent[];
  onClick: (caseId: string) => void;
}) => {
  return (
    <Box>
      <List m={0} maxH={400} overflowY="auto">
        {notifications.map(notification => {
          const {
            id,
            date,
            payload: { caseId, caseNumber }
          } = notification;
          return (
            <ListItem
              key={id}
              borderBottomWidth="1px"
              py={2}
              px={4}
              _hover={{ backgroundColor: "gray.50" }}
              onClick={() => onClick(caseId)}
              cursor="pointer"
            >
              <Box>
                <TimeAgo date={date} />
              </Box>
              <Box as="span" fontWeight="bold">
                {caseNumber}
              </Box>
              <Box>
                <EventMessage notification={notification} />
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const EventMessage = ({ notification }: { notification: AppEvent }) => {
  const {
    payload: { filename }
  } = notification;
  return (
    <>
      <Box>
        <EventMessageAction notification={notification} />
        <Box>
          <FileIcon
            filename={filename}
            color="gray.400"
            boxSize="24px"
            mr={1}
          />
          {filename}
        </Box>
      </Box>
    </>
  );
};

const EventMessageAction = ({ notification }: { notification: AppEvent }) => {
  const locale = useLocale();
  const { type, error } = notification;
  switch (type) {
    case "upload_started":
      return (
        <>
          <Icon as={AddIcon} color="gray.500" w="24px" mr={1} />
          {locale.uploadStarted}
        </>
      );
    case "upload_completed":
      return (
        <>
          <CheckIcon color="green.500" mr={1} />
          {locale.uploadCompleted}
        </>
      );
    case "upload_failed":
      return (
        <>
          <WarningIcon color="red.700" mr={1} />
          {locale.uploadFailed}
          {error && <Box color="red.700">{error.message}</Box>}
        </>
      );
    default:
      return <>{type}</>;
  }
};

const UploadingFiles = ({
  files,
  onClick
}: {
  files: Medmain.UploadFile[];
  onClick: (caseId: string) => void;
}) => {
  const locale = useLocale();
  if (files.length === 0) {
    return (
      <Box py={16} color="gray.500" textAlign="center">
        {locale.noUploadingFile}
      </Box>
    );
  }
  return (
    <Box maxH={400} overflowY="auto">
      <List>
        {files.map(file => {
          const { addedAt, caseId, displayName } = file;
          return (
            <ListItem
              key={`${addedAt.toISOString()}-${displayName}`}
              borderBottomWidth="1px"
              py={2}
              px={4}
              _hover={{ backgroundColor: "gray.50" }}
              onClick={() => onClick(caseId)}
              cursor="pointer"
            >
              <Text isTruncated mb={2}>
                {file.filename}
              </Text>
              <Box>
                <Progress value={file.uploadProgress * 100} mb={2} />
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const CountPill = ({ count, ...props }: { count: number } & BoxProps) => {
  return (
    <Box
      color="white"
      p={0}
      width="18px"
      height="18px"
      borderRadius="99px"
      fontSize="12px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="0"
      right="0"
      {...props}
    >
      {count}
    </Box>
  );
};
