import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  ButtonGroup,
  Flex,
  LayoutProps
} from "@chakra-ui/react";

import { useLocale } from "app/locale";
import { useFormState } from "app/form";
import { CaseForm, CaseFormData } from "./case-form";
import { Button, CheckIcon, CloseIcon } from "components/core";
import { useCaseForm } from "components/cases/case-form";
import { FormLayout } from "components/common/fields";

type Props = {
  id?: Medmain.Case["id"];
  pathologicCase: CaseFormData;
  canSave: boolean;
  onSubmit: (values: CaseFormData) => void;
  onCancel?: () => void;
  isNew: boolean;
  isSubmitting: boolean;
  errors?: any;
  layout?: FormLayout;
} & Pick<LayoutProps, "height">;
export const CaseEditor = ({
  id,
  pathologicCase,
  canSave,
  onSubmit,
  onCancel,
  isNew,
  isSubmitting,
  errors,
  layout,
  height = "full"
}: Props) => {
  const locale = useLocale();
  const [values, { handleChange }] = useFormState(pathologicCase);
  const { validateValues } = useCaseForm();

  const isValid = validateValues(values, isNew);

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(values);
        }}
      >
        <Box height={height}>
          <Box overflowY="auto" height="full">
            {errors && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <AlertDescription>Unable to save the case</AlertDescription>
              </Alert>
            )}
            <CaseForm
              values={values}
              onChange={handleChange}
              errors={errors}
              isNew={isNew}
              showTags
              layout={layout}
            />
          </Box>
        </Box>

        <Flex flexShrink={0} justifyContent="flex-end" mt={6}>
          <ButtonGroup>
            {onCancel && (
              <Button onClick={onCancel} leftIcon={<CloseIcon />}>
                {locale.cancelButtonLabel}
              </Button>
            )}
            <Button
              primary
              data-testid="save-case"
              type="submit"
              isDisabled={!canSave || !isValid || isSubmitting}
              leftIcon={<CheckIcon />}
            >
              {locale.saveButtonLabel}
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </>
  );
};
