// Deserialize a JSON object retrieved from the Case API,
// returning a `Case` object that can be consumed by the components
// TODO: generalize using a schema?
export function deserializeCase(caseAsJSON): Medmain.Case {
  const { createdAt } = caseAsJSON;
  const deserializedCase = {
    ...caseAsJSON,
    createdAt: new Date(createdAt)
  };
  return deserializedCase;
}
