import { useMemo } from "react";
import { useLocalStorage } from "react-use";
import debugModule from "debug";

const debug = debugModule("medmain:preferences");

export type Preferences = {
  viewerVersion: "v1" | "v2";
  casesPerPage: number;
  // viewer > drawing
  drawing: {
    mode: string;
    autoSave: boolean;
    showDrawing: boolean;
    showTitle: boolean;
    showMeasurement: boolean;
    lineWidth: number;
    lineColor: string;
  };
  predictionModel: string;
  snapshot: {
    magnification: boolean;
    scale: boolean;
  };
  // we could set up other preferences related to the UI here: theme, widget settings...
};

export const defaultValues: Preferences = {
  viewerVersion: "v2",
  casesPerPage: 10,
  drawing: {
    mode: "",
    autoSave: false,
    showDrawing: true,
    showTitle: true,
    showMeasurement: true,
    lineWidth: 2,
    lineColor: "#808080"
  },
  predictionModel: "",
  snapshot: {
    magnification: true,
    scale: true
  }
};

export function usePreferences() {
  const [storedPreferences, setPreferences] = useLocalStorage(
    "pidport-preferences",
    defaultValues
  );

  return useMemo(() => {
    const preferences = { ...defaultValues, ...storedPreferences };

    function getPreference<K extends keyof Preferences>(
      key: K
    ): Preferences[K] {
      const value = preferences[key];
      debug(`Read "${key}" %j`, value);
      return value;
    }

    function setPreference<K extends keyof Preferences>(
      key: K,
      value: Preferences[K]
    ): void {
      debug(`Write "${key}" %j`, value);
      setPreferences(current => ({
        ...(current as Preferences),
        [key]: value
      }));
    }

    return { getPreference, setPreference };
  }, [setPreferences, storedPreferences]);
}
