import React from "react";
import { usePreferences, defaultValues } from "app/preferences";
import { Redirect, useLocation } from "react-router-dom";
import { VIEWER_PAGE, IMAGE_VIEWER_PAGE } from "routes";

export const ViewerSwitcher = () => {
  const location = useLocation();
  const { getPreference } = usePreferences();
  const viewerVersion = getPreference("viewerVersion");

  const pathNames = {
    v1: IMAGE_VIEWER_PAGE.usePath(), // OpenSeadragon Image Viewer
    v2: VIEWER_PAGE.usePath() // DeckGL Viewer
  };

  const viewerPathName =
    pathNames[viewerVersion] || defaultValues.viewerVersion;

  return <Redirect to={{ ...location, pathname: viewerPathName }} />;
};
