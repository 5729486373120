import { Machine, assign, MachineConfig, MachineOptions } from "xstate";
import { useMachine } from "@xstate/react";
import { createContainer } from "unstated-next";
import pMap from "p-map";
import debugModule from "debug";
import { useToast } from "@chakra-ui/react";

import { useAPI } from "api";
import { SearchOptions } from "types";
import { CaseFormData } from "components/cases/case-form";
import { PAGINATION_MINIMUM_LIMIT } from "components/common/pagination";

const debug = debugModule("medmain:csv");

export function useImportCSVStateMachine() {
  const api = useAPI();
  const toast = useToast();
  const machine = createStateMachine({ api, toast });
  const showDevTools = process.env.NODE_ENV !== "production"; // Redux DevTools browser extension to debug in local
  return useMachine(machine, { devTools: showDevTools });
}

export function useImportCSV() {
  const [current, send] = useImportCSVStateMachine();
  return { send, current, rowTotal: current.context.records.length };
}

export const ImportCSVContainer = createContainer(useImportCSV);

export type ParsedRowData = Omit<CaseFormData, "intendedOwnerOrganizationId">;

export interface CaseRecord {
  caseId: string | undefined;
  caseNumber: string;
  data: Omit<CaseFormData, "caseNumber" | "intendedOwnerOrganizationId">;
  previewStatus?: "valid" | "not-valid";
  errorType?: "CASE_NUMBER_NOT_FOUND" | "DUPLICATE_CASE_NUMBER";
  status:
    | "waiting"
    | "checking"
    | "checked"
    | "updating"
    | "updated"
    | "failed";
}

interface MachineContext {
  orgId: string;
  filename: string;
  records: CaseRecord[];
  error?: {
    message: string;
  };
}

type StateSchema = {
  states: {
    idle: {};
    checking: {};
    previewing: {};
    updating: {};
    done: {};
  };
};

type CheckEvent = {
  type: "CHECK";
  orgId: string;
  filename: string;
  data: ParsedRowData[];
};
type ParsingErrorEvent = {
  type: "PARSING_ERROR";
  message: string;
};
type FetchCaseEvent = {
  type: "FETCH_CASE";
  caseNumber: string;
  foundCases: Medmain.Case[];
};
type BackEvent = {
  type: "BACK";
};
type RunEvent = {
  type: "RUN";
};
type UpdateCaseSuccessEvent = {
  type: "UPDATE_CASE_SUCCESS";
  caseNumber: string;
};
type ResetEvent = {
  type: "RESET";
};

type MachineEvent =
  | CheckEvent
  | ParsingErrorEvent
  | FetchCaseEvent
  | BackEvent
  | RunEvent
  | UpdateCaseSuccessEvent
  | ResetEvent;

function createStateMachine({ api, toast }) {
  const initialContext: MachineContext = {
    orgId: "",
    filename: "",
    records: [] as CaseRecord[]
  };

  const config: MachineConfig<MachineContext, StateSchema, MachineEvent> = {
    id: "import-csv",
    context: initialContext,
    initial: "idle",
    states: {
      idle: {
        on: {
          CHECK: {
            actions: ["check"],
            target: "checking"
          },
          PARSING_ERROR: { actions: "showParsingError" }
        }
      },
      checking: {
        invoke: {
          src: "checkFileContent",
          onDone: {
            target: "previewing"
          },
          onError: {
            actions: ["logError"]
          }
        },
        on: {
          FETCH_CASE: { actions: ["fetchCase"] }
        }
      },
      previewing: {
        on: {
          BACK: { target: "idle", actions: ["reset"] },
          RUN: { target: "updating" }
        }
      },
      updating: {
        invoke: {
          src: "updateCases",
          onDone: {
            target: "done"
          },
          onError: {
            actions: ["logError"]
          }
        },
        on: {
          UPDATE_CASE_SUCCESS: {
            actions: ["updateCaseSuccess"]
          }
        }
      },
      done: {
        on: {
          RESET: { target: "idle", actions: ["reset"] }
        }
      }
    }
  };

  const services = {
    checkFileContent: (context, event) => async callback => {
      const { records, orgId } = context;

      const checkSingleRecord = async record => {
        const { caseNumber } = record;
        const foundCases = await findCasesByNumber({ api, orgId, caseNumber });
        callback({ type: "FETCH_CASE", caseNumber, foundCases });
      };

      await pMap(records, checkSingleRecord, { concurrency: 1 });
    },
    updateCases: (context, event) => async callback => {
      const { records } = context;

      const validRecords = records.filter(
        record => record.previewStatus === "valid"
      );

      const updateSingleRecord = async record => {
        const { caseId, caseNumber, data } = record;
        debug("Update case", caseId, caseNumber, data);
        await api.cases.update(caseId, data);
        callback({ type: "UPDATE_CASE_SUCCESS", caseNumber });
      };

      await pMap(validRecords, updateSingleRecord, { concurrency: 1 });
    }
  };

  // TODO we should be able to use assign with 2 types `Assign<Context,Event>` when defining actions
  // but there is something wrong with X-state 4.20... to be checked again in the future!
  // https://github.com/davidkpiano/xstate/issues/2276#issuecomment-864595142
  const actions = {
    check: assign<MachineContext, any>({
      orgId: (context, event) => {
        return (event as CheckEvent).orgId;
      },
      filename: (context, event) => {
        return (event as CheckEvent).filename;
      },
      records: (context, event) => {
        const { data } = event as CheckEvent;
        const records: CaseRecord[] = data.map(({ caseNumber, ...rowData }) => {
          return {
            caseNumber,
            caseId: undefined,
            data: rowData,
            status: "waiting"
          };
        });
        return records;
      },
      error: () => {
        return undefined;
      }
    }),
    showParsingError: assign<MachineContext, any>({
      error: (context, event) => {
        const { message } = event as ParsingErrorEvent;
        return { message };
      }
    }),
    fetchCase: assign<MachineContext, any>({
      records: (context, event) => {
        const { foundCases, caseNumber } = event as FetchCaseEvent;
        const { records } = context;

        const previewStatus: CaseRecord["previewStatus"] =
          foundCases.length === 1 ? "valid" : "not-valid";
        const caseId = foundCases.length === 1 ? foundCases[0].id : undefined;

        function getErrorType(): CaseRecord["errorType"] | undefined {
          if (foundCases.length === 0) return "CASE_NUMBER_NOT_FOUND";
          if (foundCases.length > 1) return "DUPLICATE_CASE_NUMBER";
          return undefined;
        }

        return updateByCaseNumber({
          records,
          caseNumber,
          changes: {
            caseId,
            previewStatus,
            status: "checked",
            errorType: getErrorType()
          }
        });
      }
    }),
    updateCaseSuccess: assign<MachineContext, any>({
      records: (context, event) => {
        const { records } = context;
        const { caseNumber } = event as UpdateCaseSuccessEvent;
        return updateByCaseNumber({
          records,
          caseNumber,
          changes: { status: "updated" }
        });
      }
    }),
    reset: assign<MachineContext, any>({
      orgId: () => "",
      filename: () => "",
      records: (context, event) => {
        return [];
      }
    }),
    logError(context, event) {
      debug(`UNEXPECTED ERROR`, event.type, event.data);
    }
  };

  const options: MachineOptions<MachineContext, MachineEvent> = {
    actions,
    services,
    guards: {},
    activities: {},
    delays: {}
  };

  return Machine(config, options);
}

async function findCasesByNumber({ api, orgId, caseNumber }) {
  const searchOptions: SearchOptions = {
    query: [
      {
        field: "caseNumber",
        operator: "is",
        value: caseNumber
      },
      {
        field: "organizationId",
        operator: "is",
        value: orgId
      }
    ],
    page: 1,
    limit: PAGINATION_MINIMUM_LIMIT,
    order: [{ field: "caseId", direction: "ASC" }]
  };
  const { data } = await api.cases.find(searchOptions);
  return data;
}

function updateByCaseNumber({ records, caseNumber, changes }) {
  return records.map(record =>
    record.caseNumber === caseNumber ? { ...record, ...changes } : record
  );
}
