import React from "react";
import { Alert, AlertIcon, Box } from "@chakra-ui/react";

import { useLocale } from "app/locale";

// From https://reactjs.org/docs/concurrent-mode-suspense.html#handling-errors
type Props = { fallback: React.ReactNode };
type State = { error: Error | null };
export class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null };
  static getDerivedStateFromError(error) {
    return { error };
  }
  render() {
    if (!!this.state.error) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export const ErrorFallback = () => {
  const locale = useLocale();

  return (
    <Box p={8}>
      <Alert status="warning" flexDirection="row">
        <AlertIcon boxSize="36px" />
        <Box>
          <Box>
            {locale.todo("Please reload the browser window or ")}
            <a href="/">click here</a>.
          </Box>
          <Box>
            {locale.todo("Contact the support if the problem persists.")}
          </Box>
        </Box>
      </Alert>
    </Box>
  );
};
