import { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { BrowserView, MobileView } from "react-device-detect";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

type Props = {
  url: string;
  width?: string | number;
  height?: string | number;
};
export const PDFViewer = ({ url, width = "100%", height = "400" }: Props) => {
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <BrowserView>
        <object data={url} type="application/pdf" width={width} height={height}>
          <embed
            src={url}
            width={width}
            height={height}
            type="application/pdf"
          />
          PDF not supported by your browser!
        </object>
      </BrowserView>
      <MobileView>
        <MobileWrapper>
          <TransformWrapper
            wheel={{ step: 0.9, wheelDisabled: true }}
            panning={{ disabled: true }}
          >
            <TransformComponent>
              <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </TransformComponent>
          </TransformWrapper>
        </MobileWrapper>
      </MobileView>
    </>
  );
};

const MobileWrapper = styled(Box)`
  overflow: hidden;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;
