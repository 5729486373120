export const SortIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.667"
    height="14.231"
    viewBox="0 0 23.667 14.231"
  >
    <g
      id="グループ_226"
      data-name="グループ 226"
      transform="translate(-1065.667 -206.419)"
    >
      <g id="list-boxes" transform="translate(1065.667 206.419)">
        <path
          id="パス_113"
          data-name="パス 113"
          d="M6.994,10.43H3.436A1.187,1.187,0,0,1,2.25,9.244V5.686A1.187,1.187,0,0,1,3.436,4.5H6.994A1.187,1.187,0,0,1,8.18,5.686V9.244A1.187,1.187,0,0,1,6.994,10.43ZM3.436,5.686V9.244H6.994V5.686Z"
          transform="translate(-2.25 -4.5)"
        />
        <path
          id="パス_114"
          data-name="パス 114"
          d="M6.994,26.18H3.436A1.187,1.187,0,0,1,2.25,24.994V21.436A1.187,1.187,0,0,1,3.436,20.25H6.994A1.187,1.187,0,0,1,8.18,21.436v3.558A1.187,1.187,0,0,1,6.994,26.18ZM3.436,21.436v3.558H6.994V21.436Z"
          transform="translate(-2.25 -11.949)"
        />
      </g>
      <g id="arrow-down-up" transform="translate(1073.893 207.002)">
        <path
          id="パス_115"
          data-name="パス 115"
          d="M24.219,7.875a.594.594,0,0,1,.594.594V19.157a.594.594,0,1,1-1.188,0V8.469A.594.594,0,0,1,24.219,7.875Z"
          transform="translate(-12.936 -6.687)"
          fillRule="evenodd"
        />
        <path
          id="パス_116"
          data-name="パス 116"
          d="M14.237,5.8a.594.594,0,0,1,.841,0L18.64,9.361a.595.595,0,0,1-.841.841L14.657,7.058,11.514,10.2a.595.595,0,1,1-.841-.841L14.236,5.8ZM3.548,14.111a.594.594,0,0,1,.841,0l3.142,3.143,3.143-3.144a.595.595,0,0,1,.841.841L7.952,18.515a.594.594,0,0,1-.841,0L3.548,14.952a.594.594,0,0,1,0-.841Z"
          transform="translate(-3.373 -5.623)"
          fillRule="evenodd"
        />
        <path
          id="パス_117"
          data-name="パス 117"
          d="M10.719,5.625a.594.594,0,0,1,.594.594V16.907a.594.594,0,0,1-1.188,0V6.219A.594.594,0,0,1,10.719,5.625Z"
          transform="translate(-6.561 -5.624)"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
