import isObjectLike from "lodash/isObjectLike";

const commonLocale = {
  // takes an object whose keys are the locale ids:
  // {en: "Hello", ja: "こんにちは"}
  // and return the keyword display name for the current locale.
  // used for keywords like the AI labels (see data/ai-labels.json)
  get(locales) {
    if (!isObjectLike(locales)) {
      return locales;
    }

    let result = locales[(this as any).$id];

    if (result === undefined) {
      const firstKey = Object.keys(locales)[0];
      result = locales[firstKey];
    }

    return result;
  },
  todo(text) {
    return text;
  }
};

export const common = {
  ...commonLocale
};

export default common;
