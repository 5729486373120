import { useState } from "react";
import {
  Alert,
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  VStack
} from "@chakra-ui/react";
import { useAsync } from "react-async";

import { useLocale } from "app/locale";
import { ImageEditableData } from "app/upload-cases-images-container";
import { useAPI } from "api";
import { Button } from "components/core";
import { RelatedModelOrganPicker, ModelPicker } from "components/common/fields";

type ImageOrganModelData = Pick<ImageEditableData, "organ" | "modelName">;
export const DialogOrganModel = ({
  images,
  close
}: {
  images: Array<Medmain.Image>;
  close: (response: boolean) => void;
}) => {
  const api = useAPI();
  const locale = useLocale();
  const [data, setData] = useState<ImageOrganModelData>({
    organ: "",
    modelName: ""
  });

  const { run: updateOrganModel, isPending, error } = useAsync({
    deferFn: () => {
      return (async () => {
        await Promise.all(
          images.map(async image => {
            const updateData = {
              imageId: image.id,
              data: {
                modelName: data.modelName ? data.modelName : null,
                organ: data.organ
              }
            };
            await api.images.update(updateData);
          })
        );
      })();
    },
    onResolve: () => {
      return close(true);
    }
  });

  const onSubmit = event => {
    event.preventDefault();
    updateOrganModel();
  };

  return (
    <>
      <ModalHeader>
        {locale.todo("Set AI Model")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text>
          {locale.todo(
            "Set model for AI bulk prediction. Organ field could be updated in batch here."
          )}
        </Text>
        <form id="organ-model" onSubmit={onSubmit}>
          <Flex justifyContent="space-between" mt={30} w="full">
            <VStack
              spacing={3}
              alignItems="flex-start"
              w="calc(100% / 2 - 10px)"
            >
              <Box fontWeight={500}>{locale.caseFields.organ}</Box>
              <Box w="full">
                <RelatedModelOrganPicker
                  value={data.organ}
                  onChange={organ => {
                    setData(data => ({ ...data, organ }));
                  }}
                  currentModel={data.modelName}
                />
              </Box>
            </VStack>
            <VStack
              spacing={3}
              alignItems="flex-start"
              w="calc(100% / 2 - 10px)"
            >
              <Box fontWeight={500}>{locale.caseFields.model}</Box>
              <Box w="full">
                <ModelPicker
                  value={data.modelName}
                  onChange={modelName => {
                    setData(data => ({ ...data, modelName }));
                  }}
                  currentOrgan={data.organ}
                />
              </Box>
            </VStack>
          </Flex>
          {error && (
            <Alert status="error" mt={4}>
              {locale.todo("Unable to update the images")} {error.message}
            </Alert>
          )}
        </form>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="flex-end" w="full">
          <Button
            primary
            type="submit"
            form="organ-model"
            isLoading={isPending}
          >
            {locale.todo("Update")}
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
};
