import { useAsync } from "react-async";
import { useAPI } from "api";

export const PRIVATE_LABEL_QUERY_FIELD = "privateLabel";
export const PUBLIC_LABEL_QUERY_FIELD = "publicLabel";

export const useLabelled = (reload?: () => void) => {
  const api = useAPI();

  const togglePrivateLabelledCase = useAsync({
    deferFn: data => {
      const { caseId, images } = data[0];
      const values: {
        caseId: Medmain.Case["id"];
        data: Omit<Medmain.Labelled, "id">;
      } = {
        caseId,
        data: {
          visibilityType: "private",
          labelName: "StarIcon-#edcb0e"
        }
      };

      return images.some((image: Medmain.Image) => image.privateLabelled)
        ? api.cases.labelled.delete(values)
        : api.cases.labelled.create(values);
    },
    onResolve: () => {
      if (reload) reload();
    }
  });

  const togglePublicLabelledCase = useAsync({
    deferFn: data => {
      const { caseId, images, name, color } = data[0];
      const values: {
        caseId: Medmain.Case["id"];
        data: Omit<Medmain.Labelled, "id">;
      } = {
        caseId,
        data: {
          visibilityType: "public",
          labelName: `${name}-${color}`
        }
      };

      return images.every((image: Medmain.Image) =>
        image.publicLabelleds?.find(
          item => item.labelName === `${name}-${color}`
        )
      )
        ? api.cases.labelled.delete(values)
        : api.cases.labelled.create(values);
    },
    onResolve: () => {
      if (reload) reload();
    }
  });

  const togglePrivateLabelledImage = useAsync<Medmain.Labelled | string>({
    deferFn: data => {
      const { imageId, privateLabelled } = data[0];

      return privateLabelled
        ? api.images.labelled.delete(imageId, privateLabelled.id)
        : api.images.labelled.create({
            imageId,
            data: {
              visibilityType: "private",
              labelName: "StarIcon-#edcb0e"
            }
          });
    },
    onResolve: () => {
      if (reload) reload();
    }
  });

  const togglePublicLabelledImage = useAsync<Medmain.Labelled | string>({
    deferFn: data => {
      const { imageId, publicLabelled, name, color } = data[0];

      return publicLabelled
        ? api.images.labelled.delete(imageId, publicLabelled.id)
        : api.images.labelled.create({
            imageId,
            data: {
              visibilityType: "public",
              labelName: `${name}-${color}`
            }
          });
    },
    onResolve: () => {
      if (reload) reload();
    }
  });

  return {
    togglePrivateLabelledCase,
    togglePrivateLabelledImage,
    togglePublicLabelledCase,
    togglePublicLabelledImage
  };
};

export * from "./icons";
