import React from "react";

import { useLocale } from "app/locale";
import { useAccount } from "app/auth-container";
import { hasPermission } from "app/permissions";
import { OrgPicker } from "components/organizations/org-picker";
import { ErrorLabel } from "components/common/error-label";
import {
  EditableField,
  FieldGroup,
  FormLayout
} from "components/common/fields";
import { Input, Textarea } from "components/core";
import { TagPicker } from "components/common/tag-picker";

export type CaseFormData = Pick<
  Medmain.Case,
  | "caseNumber"
  | "comments"
  | "diagnosis"
  | "grossFindings"
  | "intendedOwnerOrganizationId"
  | "microscopicFindings"
  | "tags"
> & { ownerOrganizationId: Medmain.Case["organizationId"] };

type Props = {
  values: CaseFormData;
  onChange: (fieldName: string) => (value: any) => void;
  errors?: any;
  isNew: boolean;
  showTags: boolean;
  showCaseNumber?: boolean; // when included in the Bulk Upload page, the "case number" field is not needed
  layout?: FormLayout;
};
export const CaseForm = ({
  values,
  onChange,
  errors,
  isNew,
  showTags,
  showCaseNumber = true,
  layout = "horizontal"
}: Props) => {
  const locale = useLocale();
  const { isSuper } = useAccount();

  const handleInputChange = (fieldName: string) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    return onChange(fieldName)(event.currentTarget.value);
  };

  // TODO: include field error management at the <Field> level?
  function showFieldError(fieldName) {
    if (!errors) return null;
    const errorMessage = errors[fieldName];
    if (!errorMessage) return null;
    return <ErrorLabel>{errorMessage}</ErrorLabel>;
  }

  const {
    caseNumber,
    comments,
    diagnosis,
    grossFindings,
    intendedOwnerOrganizationId,
    microscopicFindings,
    ownerOrganizationId,
    tags
  } = values;

  return (
    <FieldGroup layout={layout}>
      {isNew && (
        <EditableField
          label={locale.caseFields.organization}
          htmlFor="organization"
          isRequired={isNew}
        >
          <OrgPicker
            popupClassName="organization"
            id="organization"
            permissionType="org/create_case"
            defaultOrgId={ownerOrganizationId}
            onChange={onChange("ownerOrganizationId")}
            persistOrgChoice={false}
            autoFocus={isNew}
          />
        </EditableField>
      )}
      {isSuper() && (
        <EditableField
          label={locale.caseFields.slideProvider}
          isRequired={isNew}
        >
          <OrgPicker
            popupClassName="slide_provider"
            id="slide_provider"
            permissionType="org/create_case"
            defaultOrgId={intendedOwnerOrganizationId}
            onChange={onChange("intendedOwnerOrganizationId")}
            persistOrgChoice={false}
          />
        </EditableField>
      )}

      {showCaseNumber && (
        <EditableField
          label={locale.caseFields.caseNumber}
          isRequired
          htmlFor="caseNumber"
        >
          <Input
            id="caseNumber"
            name="caseNumber"
            value={caseNumber}
            onChange={handleInputChange("caseNumber")}
            isRequired
          />
          {showFieldError("caseNumber")}
        </EditableField>
      )}

      <EditableField label={locale.caseFields.diagnosis} htmlFor="diagnosis">
        <Input
          id="diagnosis"
          name="diagnosis"
          value={diagnosis}
          onChange={handleInputChange("diagnosis")}
        />
        {showFieldError("diagnosis")}
      </EditableField>

      {showTags && (
        <EditableField label={locale.caseFields.tags} htmlFor="tags">
          <TagPicker id="tags" tags={tags} onChange={onChange("tags")} />
        </EditableField>
      )}

      <EditableField
        label={locale.caseFields.grossFindings}
        htmlFor="grossFindings"
      >
        <Textarea
          id="grossFindings"
          name="grossFindings"
          value={grossFindings}
          onChange={handleInputChange("grossFindings")}
          height={150}
        />
        {showFieldError("grossFindings")}
      </EditableField>

      <EditableField
        label={locale.caseFields.microscopicFindings}
        htmlFor="microscopicFindings"
      >
        <Textarea
          id="microscopicFindings"
          name="microscopicFindings"
          value={microscopicFindings}
          onChange={handleInputChange("microscopicFindings")}
          height={150}
        />
        {showFieldError("microscopicFindings")}
      </EditableField>

      <EditableField label={locale.caseFields.comments} htmlFor="comments">
        <Textarea
          id="comments"
          name="comments"
          value={comments}
          onChange={handleInputChange("comments")}
          height={150}
        />
        {showFieldError("comments")}
      </EditableField>
    </FieldGroup>
  );
};

export function useCaseForm() {
  const { isSuper } = useAccount();
  const defaultOrgId = useNewCaseDefaultOrgId();

  function getDefaultValues(): CaseFormData {
    return {
      caseNumber: "",
      comments: "",
      diagnosis: "",
      grossFindings: "",
      intendedOwnerOrganizationId: isSuper() ? defaultOrgId : null,
      microscopicFindings: "",
      ownerOrganizationId: defaultOrgId,
      tags: []
    };
  }

  // TODO implement proper form validation using `react-hook-form`?
  function validateValues(values: CaseFormData, isNewCase: boolean): boolean {
    if (isNewCase) {
      if (!validateOrganizationIds(values)) return false;
    }
    if (!values.caseNumber) return false;
    return true;
  }

  function validateOrganizationIds(values: CaseFormData): boolean {
    if (!values.ownerOrganizationId) return false;
    if (isSuper() && !values.intendedOwnerOrganizationId) return false;
    return true;
  }

  return { getDefaultValues, validateValues, validateOrganizationIds };
}

export function useNewCaseDefaultOrgId() {
  const { currentOrgId, organizations } = useAccount();

  // For super users who have not selected any org previously, a choice is required
  if (currentOrgId === "*") return "";

  const allowedOrgIds = organizations
    .filter(org => hasPermission("org/create_case", org))
    .map(org => org.id);
  const defaultOrgId = allowedOrgIds.includes(currentOrgId)
    ? currentOrgId
    : allowedOrgIds?.[0];

  return defaultOrgId;
}
