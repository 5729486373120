export function resolveLocale({
  localeId,
  locales,
  defaultLocaleId,
  fallbackLocaleId
}) {
  const availableLocales = [localeId, defaultLocaleId, fallbackLocaleId] // the order matters!
    .filter(id => Boolean(locales[id])) // keep only ids that have a related locale
    .map(id => locales[id]);
  const firstAvailableLocale = availableLocales[0];
  if (!firstAvailableLocale) {
    throw new Error(`Unable to resolve "${localeId}"`);
  }
  return firstAvailableLocale;
}
