import { useLocation, useHistory } from "react-router-dom";
import { parse } from "qs";

import { globalDefaultSearchParams, useSearch } from "app/search";
import { usePreferences } from "app/preferences";
import { SearchOptions } from "types";

export function useParseURL(viewDefaultSearchParams) {
  // Merge view and global parameters because each view can specify its own `order` and `limit` parameters
  const defaultSearchParams = {
    ...globalDefaultSearchParams,
    ...viewDefaultSearchParams
  };

  const location = useLocation();
  return parseQueryStringToSearchParams(location.search, defaultSearchParams);
}

export function usePagination({
  searchOptions,
  total
}: {
  searchOptions: SearchOptions;
  total: number;
}) {
  const history = useHistory();
  const { page, limit } = searchOptions;
  const { getNextLocation } = useSearch(searchOptions);
  const { setPreference } = usePreferences();

  function updateSearchParams(changes: { page?: number; limit?: number }) {
    const nextLocation = getNextLocation(changes);
    history.push(nextLocation);
    if (changes.limit) {
      setPreference("casesPerPage", changes.limit); // TODO trigger this effect only from the Case List page?
    }
  }

  return {
    hasPreviousPage: page > 1,
    hasNextPage: limit === undefined ? false : page < Math.ceil(total / limit),
    offset: limit === undefined ? 0 : (page - 1) * limit,
    updateSearchParams
  };
}

export function parseQueryStringToSearchParams(
  queryString: string,
  defaults: SearchOptions = globalDefaultSearchParams
): SearchOptions {
  const { query, page, limit, order, hasImage, ...otherParams } = parse(
    queryString,
    {
      ignoreQueryPrefix: true
    }
  );

  return {
    query: parseObject(query, defaults.query),
    page: parseInteger(page, {
      defaultValue: defaults.page,
      minValue: 1
    }),
    limit:
      defaults.limit === undefined
        ? undefined
        : parseInteger(limit, {
            defaultValue: defaults.limit,
            minValue: 1
          }),
    order: parseObject(order, defaults.order),
    hasImage: parseBool(hasImage),
    ...otherParams // includes the `visibility` parameter
  };
}

// string from the URL => JSON object
function parseObject(stringifiedObject: string, defaultObject: object) {
  if (!stringifiedObject) return defaultObject;
  try {
    const decodedString = decodeURIComponent(stringifiedObject);
    return JSON.parse(decodedString);
  } catch (error) {
    console.error(`Invalid JSON content "${stringifiedObject}"`);
    return defaultObject;
  }
}

// Parse a number from the URL, fallback to the provided `defaultValue` if the input is not a number
interface ParseIntegerOptions {
  defaultValue: number;
  minValue: number;
}

export function parseInteger(
  value: string | number,
  { defaultValue, minValue }: ParseIntegerOptions
): number {
  const number = isNaN(Number(value))
    ? defaultValue
    : parseInt(value.toString(), 10);
  return number < minValue ? minValue : number;
}

function parseBool(value: string): boolean | undefined {
  const keywords = {
    true: true,
    false: false
  };
  return keywords[value];
}
