import React from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";

export const Heading1 = (props: HeadingProps) => {
  return <Heading size="lg" fontWeight="normal" mb={4} {...props} />;
};

export const Heading2 = (props: HeadingProps) => {
  return <Heading size="md" fontWeight="normal" mb={4} {...props} />;
};

export const Heading3 = (props: HeadingProps) => {
  return <Heading size="sm" fontWeight="normal" mb={4} {...props} />;
};
