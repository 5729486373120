import { english } from "./english";

const japaneseLocale = {
  $id: "ja",
  $name: "日本語",

  // === Formatting ===

  months: {
    full: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ]
  },

  days: {
    full: [
      "日曜日",
      "月曜日",
      "火曜日",
      "水曜日",
      "木曜日",
      "金曜日",
      "土曜日"
    ],
    partial: ["日", "月", "火", "水", "木", "金", "土"]
  }
};

export const japanese = {
  ...english, // we use English as the base
  ...japaneseLocale

  // TODO define all messages in Japanese!
};
