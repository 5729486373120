import { LocaleDictionary } from "locales/english";

// Return either the full name (made of first and last names in the right order, depending on the language)
// or the email address, if the user has not set the names.
export function getAccountDisplayName(
  profile: {
    firstName?: string | null;
    lastName?: string | null;
    email: string;
  },
  locale: LocaleDictionary
): string {
  const { firstName, lastName, email } = profile;

  if (!lastName) {
    return email || "<no email address>";
  }

  return locale.formatFullName({ firstName, lastName });
}
