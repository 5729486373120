import { useHistory, useLocation } from "react-router-dom";
import { parse, stringify } from "qs";

import { useParseURL } from "./pagination";
import { formatSearchParamsToQueryString } from "app/search";

export function usePersistSearchParams(params = {}) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useParseURL(params);

  const getItemLocation = (
    pathname: string,
    cases: [string, string],
    index: string,
    total: string
  ) => ({
    pathname,
    search: `${encodeSearchParams(searchParams)}&${stringify(
      { prev: cases[0], next: cases[1], total },
      { encode: true, skipNulls: true }
    )}&index=${index}`
  });

  const showItem = (
    pathname: string,
    cases: [string, string],
    index: string,
    total: string
  ) => {
    const nextLocation = getItemLocation(pathname, cases, index, total);
    history.push(nextLocation);
  };

  function encodeSearchParams(searchParams) {
    const fromParams = {
      pathname: location.pathname,
      ...searchParams
    };
    return stringify({ from: fromParams }, { encode: true, skipNulls: true });
  }

  const showList = defaultPathname => {
    const params = parse(location.search, {
      ignoreQueryPrefix: true
    });
    if (!params.from) {
      return history.push(defaultPathname);
    }
    const { pathname, ...searchParams } = params.from;
    const queryString = formatSearchParamsToQueryString(searchParams);

    history.push({
      pathname: pathname,
      search: queryString
    });
  };

  return {
    getItemLocation,
    showItem,
    showList
  };
}
