import React from "react";
import "mobx-react-lite/batchingForReactDom";
import ReactDOM from "react-dom";

import { Root } from "./root";
import { APIContainer } from "api";
import { AccountContainer, AuthContainer } from "app/auth-container";
import { LocaleContainer } from "app/locale";

const render = (App: typeof Root) =>
  ReactDOM.render(
    <AuthContainer.Provider>
      <APIContainer.Provider>
        <AccountContainer.Provider>
          <LocaleContainer.Provider>
            <App />
          </LocaleContainer.Provider>
        </AccountContainer.Provider>
      </APIContainer.Provider>
    </AuthContainer.Provider>,
    document.getElementById("root")
  );

render(Root);

// `@types/webpack-env` has to be added to `dependencies` (instead of `devDependencies`)
// to avoid an error when building in production mode because of `module.hot`.
// See https://github.com/medmain/pidport-next/pull/230#discussion_r468907760
if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./root", () => {
      const NextApp = require("./root").Root;
      render(NextApp);
    });
  }
}
