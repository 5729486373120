import { useList } from "react-use";
import { createContainer } from "unstated-next";
import { useToast } from "@chakra-ui/react";
import { useRef } from "react";
import throttle from "lodash/throttle";

import { useLocale } from "app/locale";

interface NotificationEvent {
  id: number;
  date: Date;
  error?: Error;
}

export type UploadEventPayload = {
  caseId: Medmain.Case["id"];
  caseNumber: Medmain.Case["caseNumber"];
  filename: string;
};

type UploadStartEvent = {
  type: "upload_started";
  payload: UploadEventPayload;
} & NotificationEvent;

type UploadCompleteEvent = {
  type: "upload_completed";
  payload: UploadEventPayload;
} & NotificationEvent;

type UploadErrorEvent = {
  type: "upload_failed";
  payload: UploadEventPayload;
} & NotificationEvent;

export type AppEvent =
  | UploadStartEvent
  | UploadCompleteEvent
  | UploadErrorEvent;

const MAX_NUMBER_OF_NOTIFICATIONS = 50;

// A generic container that could be used for any kind of notification
function useNotifications() {
  const [notifications, { insertAt }] = useList<AppEvent>([]);
  const indexRef = useRef(0); // a simple counter incremented when messages are added to ensure keys are unique

  const add = ({
    type,
    payload,
    error
  }: {
    type: any;
    payload: any;
    error?: Error;
  }) => {
    insertAt(0, {
      id: indexRef.current,
      date: new Date(),
      type,
      payload,
      error
    }); // newest items first
    indexRef.current++;
  };

  return {
    add,
    notifications: notifications.slice(0, MAX_NUMBER_OF_NOTIFICATIONS) // show only the latest N messages
  };
}

export const NotificationsContainer = createContainer(useNotifications);

// Specific hook to send notifications about Case Upload flow
export function useCaseUploadNotification() {
  const locale = useLocale();
  const toast = useToast();
  const { add } = NotificationsContainer.useContainer();

  const notifyStartUpload = (payload: UploadEventPayload) =>
    add({ type: "upload_started", payload });

  //  Ensure we don't show too many toasts in the same time (the "Tetris" effect!)
  // TODO: check toast.closeAll() when we migrate to ChakraUI v1.0
  const duration = 5000;
  const notifyCompleteUpload = throttle(
    (payload: UploadEventPayload) => {
      add({ type: "upload_completed", payload });
      toast({
        title: locale.uploadCompleted,
        description: payload.filename,
        status: "success",
        duration,
        isClosable: true
      });
    },
    duration,
    { leading: true }
  );

  const notifyUploadError = (payload: UploadEventPayload, error: Error) => {
    add({ type: "upload_failed", payload, error });
    toast({
      title: locale.uploadFailed,
      description: `Failed to upload ${payload.filename} ${error.message}`,
      status: "error",
      isClosable: true
    });
  };

  return {
    notifyStartUpload,
    notifyCompleteUpload,
    notifyUploadError
  };
}
