import React from "react";
import { Text, BoxProps } from "@chakra-ui/react";

export const NumberOf = ({ items, name }) => {
  const count = items.length;

  if (count === 0) return <>no {name}</>;
  if (count === 1) return <>1 {name}</>;
  return <>{`${count} ${name}s`}</>;
};

type Props = { items: string[]; limit: number } & BoxProps;
export const TruncatedList = ({ items, limit, ...props }: Props) => {
  return (
    <Text fontStyle="italic" display="inline" {...props}>
      {truncate({ items, limit })}
    </Text>
  );
};

const truncate = ({ items, limit }) => {
  const isTruncated = items.length > limit;
  const displayedItems = isTruncated ? items.slice(0, limit) : items;
  return displayedItems.join(", ") + (isTruncated ? ", ..." : "");
};
