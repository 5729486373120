import React from "react";
import { format, formatDistanceToNow } from "date-fns";
import { BoxProps, Box } from "@chakra-ui/react";

// Format a date displaying the time from now: "1 hour ago", "1 month ago"...
// see https://date-fns.org/v2.0.1/docs/formatDistanceToNow
type Props = { date: Date | string } & BoxProps;
export const TimeAgo = ({ date, ...otherProps }: Props) => {
  const formattedDate = formatTimeAgo(normalizeDate(date));

  return (
    <Box as="span" {...otherProps}>
      {formattedDate}
    </Box>
  );
};

export function formatTimeAgo(date: Date): string {
  let formattedDate = formatDistanceToNow(date, { addSuffix: true });
  formattedDate = formattedDate
    .replace("less than a minute ago", "just now") // for 0 ... 30 secs distance
    .replace(/about |almost |less than |over /, ""); // remove `about` and `almost` prefixes
  return formattedDate;
}

export const DateTime = ({
  date,
  mask,
  ...otherProps
}: Props & { mask?: string }) => {
  const formattedDate = formatDateTime(normalizeDate(date), { mask });

  return (
    <Box as="span" {...otherProps}>
      {formattedDate}
    </Box>
  );
};

export function formatDateTime(
  date: Date,
  { mask = `yyyy-MM-dd 'at' HH:mm` } = {}
) {
  return format(date, mask);
}

function normalizeDate(date: Date | string): Date {
  return typeof date === "string" ? new Date(date) : date;
}
