import React, { useCallback, memo } from "react";
import { useAsync } from "react-async";
import { Box, BoxProps, Flex } from "@chakra-ui/react";
import debugModule from "debug";

import { useAPI } from "api";
import { useAuth } from "app/auth-container";

const debug = debugModule("medmain");

type Props = {
  image: Medmain.Image;
} & BoxProps;
export const ImageLoader = memo(({ image, ...props }: Props) => {
  const api = useAPI();
  const { getTokenSilently } = useAuth();
  const url = api.images.getSmallImageURL(image.id);

  const loadImageData = useCallback(async () => {
    const accessToken = await getTokenSilently();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetch(url, { headers });
    const blob = await response.blob();
    const dataURL: string = await blobToDataURL(blob);
    return dataURL;
  }, [getTokenSilently, url]);

  const { data: dataURL, error, isPending } = useAsync(loadImageData);

  if (error) {
    return (
      <Placeholder color="red.600">
        <Box>Error</Box>
        {error.message && <Box>{error.message}</Box>}
      </Placeholder>
    );
  }

  if (isPending) {
    debug(`Loading image blob ${image.filename}`);
    return <Placeholder color="gray.500">Loading...</Placeholder>;
  }

  debug(`Loaded ${image.filename}`);

  // We use a background image instead of a simple `<img>` tag
  // to fill all the available space, preserve the aspect ratio without truncating any part
  return (
    <Box
      backgroundImage={`url("${dataURL}")`}
      bgSize="contain"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      w="100%"
      h="100%"
      {...props}
    />
  );
});

export function blobToDataURL(imageBlob: Blob): Promise<string> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = event =>
      reject(new Error("Unable to convert the image blob"));
    reader.readAsDataURL(imageBlob);
  });
}

const Placeholder = (props: BoxProps) => {
  return (
    <Flex
      flexDirection="column"
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  );
};
