import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button } from "@chakra-ui/react";

import { Heading2 } from "components/core";

export const NotFound = () => {
  return (
    <Box p={8} textAlign="center">
      <Heading2>Sorry, the page you requested was not found.</Heading2>
      <Button as={RouterLink} to="/" variant="outline">
        Go to the Home page
      </Button>
    </Box>
  );
};
