import React from "react";
import PropTypes from "prop-types";

type Props = {
  onChoose: (any) => void;
  acceptedTypes: string[];
  acceptedExtensions: string[];
};
export class FileChooser extends React.Component<Props> {
  fileInputRef: React.RefObject<HTMLInputElement>;

  static propTypes = {
    onChoose: PropTypes.func.isRequired,
    acceptedTypes: PropTypes.array,
    acceptedExtensions: PropTypes.array
  };

  static defaultProps = {
    acceptedTypes: [],
    acceptedExtensions: []
  };

  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  open = () => {
    this.fileInputRef?.current?.click();
  };

  handleFileInputChange = () => {
    const { onChoose } = this.props;

    const element = this.fileInputRef.current;
    if (!element?.files) throw new Error("No HTML input element");

    const files: File[] = [];
    for (let i = 0; i < element.files.length; i++) {
      files.push(element.files[i]);
    }

    if (files.length) {
      onChoose(files);
    }

    element.value = "";
    if (element.value) {
      // For IE
      element.type = "text";
      element.type = "file";
    }
  };

  render() {
    const { acceptedTypes, acceptedExtensions } = this.props;

    return (
      <input
        type="file"
        ref={this.fileInputRef}
        onChange={this.handleFileInputChange}
        accept={[...acceptedTypes, ...acceptedExtensions].join(",")}
        multiple
        style={{ display: "none" }}
      />
    );
  }
}
