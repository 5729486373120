import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text
} from "@chakra-ui/react";
import { useLocale } from "app/locale";

export const DialogSkippedAIAnalysis = ({
  images
}: {
  images: (Medmain.Image & { caseNumber: Medmain.Case["caseNumber"] })[];
}) => {
  const locale = useLocale();

  const columns = ["Case Number", "Filename", "Reason"];
  const skippedImages = images.map(image => ({
    caseNumber: image.caseNumber,
    filename: image.displayName,
    reason: image?.modelName ? "Already analyzed" : "Empty Model"
  }));

  return (
    <>
      <ModalHeader px={9} pt={9}>
        {locale.todo("Skip file list")}
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody px={9} pb={12}>
        <Text>{locale.todo("The files below have skipped AI analysis.")}</Text>
        <Box w="full" mt={8}>
          <Flex>
            {columns.map((column, i) => (
              <Box key={i} w={180} fontWeight={600}>
                {column}
              </Box>
            ))}
          </Flex>
          {skippedImages.map((image, j) => (
            <Flex key={j} py={4} borderBottom="1px" borderColor="dividerColor">
              <Box w={180}>{image.caseNumber}</Box>
              <Box w={180}>{image.filename}</Box>
              <Box>{image.reason}</Box>
            </Flex>
          ))}
        </Box>
      </ModalBody>
    </>
  );
};
