import React from "react";
import { Spinner as ChakraSpinner, SpinnerProps, Flex } from "@chakra-ui/react";

const baseProps: SpinnerProps = {
  thickness: "3px",
  speed: "1s",
  size: "lg",
  color: "primary.500",
  emptyColor: "gray.100"
};

export const Spinner = (props: SpinnerProps) => (
  <Flex justifyContent={props.justifyContent || "center"}>
    <ChakraSpinner {...baseProps} {...props} />
  </Flex>
);
