import React, { useState } from "react";
import { useAsync } from "react-async";
import omit from "lodash/omit";
import { useToast } from "@chakra-ui/react";

import { useAPI } from "api";
import { hasPermission } from "app/permissions";
import { CaseEditor } from "./case-editor";
import { CaseFormData } from "./case-form";
import { FormLayout } from "components/common/fields";
import { FetchCaseState } from "pages/cases/case-item/case-item-fetch-data";

type Props = Pick<FetchCaseState, "pathologicCase" | "reload"> & {
  onSaveSuccess?: () => void;
  onCancel: () => void;
  layout?: FormLayout;
};
export const CaseEditDetails = ({
  pathologicCase,
  reload,
  onSaveSuccess,
  onCancel,
  layout
}: Props) => {
  const api = useAPI();
  const [errors, setErrors] = useState(undefined);
  const toast = useToast();
  const { id, caseNumber } = pathologicCase;
  const canUpdateCase = hasPermission("case/update", pathologicCase);

  const saveCase = async ([values]: any[]) => {
    try {
      await api.cases.update(id, cleanCaseFormData(values) as CaseFormData);
      toast({
        description: `Case "${caseNumber}" updated`,
        status: "success",
        isClosable: true
      });
      reload(values); // to avoid showing stale data
      if (onSaveSuccess) onSaveSuccess();
    } catch (error) {
      if (error.errors) {
        // Server-side validation errors
        setErrors(error.errors);
        return;
      }
      // Other errors, E.g.: assign an existing case number
      toast({
        title: `Unable to update the case`,
        description: error.message || "Unexpected error",
        status: "error"
      });
    }
  };

  const { run: save, isPending: isSaving } = useAsync({ deferFn: saveCase });

  const caseData = {
    ...pathologicCase,
    ownerOrganizationId: pathologicCase.organizationId
  };
  delete (caseData as Partial<typeof caseData>).organizationId; // Clean case data to be submitted

  return (
    <CaseEditor
      id={pathologicCase.id}
      pathologicCase={caseData}
      canSave={canUpdateCase}
      onSubmit={save}
      onCancel={onCancel}
      isNew={false}
      isSubmitting={isSaving}
      errors={errors}
      layout={layout}
      height={{ base: "auto", lg: "505px" }}
    />
  );
};

// Before submitting, remove attributes that cannot be modified
function cleanCaseFormData(values) {
  return omit(values, ["id", "images", "attachments"]);
}
