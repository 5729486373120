import debugModule from "debug";

import { getFileContentType } from "utils/files";

const debug = debugModule("medmain:upload");

export function uploadFileByXHR<T = any>({
  url,
  file,
  accessToken,
  onProgress,
  method = "POST"
}: {
  file: File;
  url: string;
  accessToken: string;
  onProgress: (progress: number) => void;
  method?: string;
}) {
  debug("Upload the image for real", file);
  return new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(method, url, true);

    xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    const contentType = getFileContentType(file); // some `File` object don't have any `type` (E.g. .svs and .ndpi files)
    xhr.setRequestHeader("Content-Type", contentType);

    const throwError = message => {
      const err = new Error(message);
      reject(err);
    };

    xhr.onload = () => {
      const json = parseResponse(xhr.responseText);
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(json);
      } else {
        const message = json.message || `HTTP Status: ${xhr.status}`;
        throwError(`(${message})`);
      }
      xhr.onreadystatechange = null; // TODO: check if it's enough to free up the memory
    };

    xhr.onerror = () => {
      throwError(`XMLHttpRequest error during image upload`);
    };

    xhr.onabort = () => {
      throwError(`Upload has been aborted`);
    };

    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const progress = event.loaded / event.total;
        onProgress(progress);
      }
    };

    xhr.send(file);
  });
}

function parseResponse(text: string) {
  try {
    return text ? JSON.parse(text) : null; // Uploading a PDF returns an empty response while uploading an image returns data
  } catch (error) {
    console.error(`Unable to parse to JSON the response`, text);
    return { text };
  }
}
