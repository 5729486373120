import React from "react";
import { Spinner } from "./spinner";
import { Flex } from "@chakra-ui/react";

interface Props {
  bg?: string;
}

export const Splash = (props: Props) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    w="100vw"
    h="100vh"
    bg={props.bg}
  >
    <Spinner size="xl" />
  </Flex>
);
